var reinitHierarchyStructureDate;

(function($){
    var fn = $(".comp_hierarchy_structure");

    if (fn.length) {

        let level_select = fn.find('[data-lib-slimselect="position"]');
        let country_select = fn.find('[data-lib-slimselect="country"]');

        fn.on("click", "[data-toggle-filter]", function(){
            if (!$(this).hasClass("mod--active")) {
                $(this).addClass("mod--active").closest(fn).find(".wrp_comp_filter").slideDown(300);
            } else {
                $(this).removeClass("mod--active").closest(fn).find(".wrp_comp_filter").slideUp(300);
            }
        });

        function formatLevel(level) {
            return "<span class=\""+ level.data("color") +"\">" + level.text + "</span>"
        }

        if(level_select.length) {
            $.getStyle(cdnjs.slimselect_css);
            $.getScript(cdnjs.slimselect).done(function () {
                let data = [];
                level_select.find("option").each(function () {
                    let el = $(this);
                    data.push({innerHTML: `<div class="elm_select_item ${el.data("color")}">${el.text()}</div>`, text: `${el.text()}`, value: `${parseInt(el.attr('data-value'))}`})
                });

                new SlimSelect({
                    select: level_select[0],
                    showSearch: false,
                    data: data
                });

                new SlimSelect({
                    select: country_select[0],
                    showSearch: false
                });

            })
        }

        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+lang+".min.js").done(function () {
            let now = new Date();

            var appCountry = $('html').attr('data-country');

            let minDate = moment('1.1.2017','DD.MM.YYYY');
            if(appCountry === 'cz' || appCountry === 'sk') {
                minDate = moment('1.1.2008','DD.MM.YYYY');
            } else if(appCountry === 'pl') {
                minDate = moment('1.1.2012','DD.MM.YYYY');
            }

            reinitHierarchyStructureDate = function() {

                if(typeof hierarchyDatepickerInstance !== 'undefined' && hierarchyDatepickerInstance) {
                    hierarchyDatepickerInstance.destroy();
                }

                let dp_elm = $('#layout_main').find(".elm_head_datepicker");
                let hierarchyDatepickerInstance = dp_elm.find("input[type=\"text\"]").datepicker({
                    minDate: minDate.toDate(),
                    maxDate: now,
                    view: "months",
                    minView: "months",
                    language: lang,
                    dateFormat: "MM yyyy",
                    altField: dp_elm.find("input[type=\"hidden\"]"),
                    altFieldDateFormat: "yyyy/mm",
                    autoClose: true,
                    position: "bottom right",
                    onShow: function () {
                        $('.datepickers-container').addClass("state--active");
                        setTimeout(function() {
                            $('.datepickers-container .datepicker').addClass('active-after');
                        },1000);
                    },
                    onHide: function () {
                        $('.datepickers-container').removeClass("state--active");
                        $('.datepickers-container .datepicker').removeClass('active-after');
                    },
                    onSelect: function(formattedDate, date, inst) {
                        if(typeof doFilterHierarchy !== 'undefined') {
                            doFilterHierarchy();
                        }
                    }
                }).data("datepicker");
            };
            reinitHierarchyStructureDate();

        });

        fn.on("click","[data-click=\"expand\"]",function (e) {
            e.preventDefault();
            let el = $(this).closest(".part_item_hierarchy_structure");

            let _this = $(this);

            if($(this).is('[data-load-children]') && parseInt($(this).attr('data-children-count')) > 0) {
                if(el.next().is('.elm_content_step')) {
                    //already loaded do nothing
                } else {

                    let loadingElem = el.children('.wrp_item_body');
                    loadingElem.addClass('mod--loading');

                    let ajaxLink = $(this).attr('data-load-children');

                    $.ajax({
                        url: ajaxLink,
                        method: 'post',
                        dataType: 'json',
                        data: {},
                        timeout: 60000
                    }).done(function (data) {
                        if(data.data) {

                            let dataHtml = $(data.data);
                            el.after(dataHtml);

                            let isActive = el.hasClass("mod--active");
                            let currentLogin = _this.closest('[data-hierarchy-structure-item]').attr('data-login');

                            if(isActive) {
                                el.removeClass("mod--active");
                                el.nextAll(".elm_content_step").stop().slideUp();

                                let highlighted = $('.part_item_hierarchy_structure .wrp_item_body.hierarchy_highlight_item');
                                if(highlighted.length) {
                                    highlighted.removeClass('hierarchy_highlight_item');
                                    highlighted.parent().hide();
                                }

                            }
                            else {
                                if(el.next(".elm_content_step").length) {
                                    el.addClass("mod--active");
                                    el.nextAll(".elm_content_step").stop().slideDown();
                                    setTimeout(function () {
                                        el.nextAll(".elm_content_step").removeClass("state--loading");
                                    },1000);
                                }
                            }

                            if(!isActive) {
                                $('[data-hierarchy-structure-item][data-parent-login="' + currentLogin + '"]').show();
                            }

                            loadingElem.removeClass('mod--loading');
                        }
                    });

                    return;
                }
            }

            let isActive = el.hasClass("mod--active");
            let currentLogin = $(this).closest('[data-hierarchy-structure-item]').attr('data-login');

            if(isActive) {
                el.removeClass("mod--active");
                el.nextAll(".elm_content_step").stop().slideUp();

                let highlighted = $('.part_item_hierarchy_structure .wrp_item_body.hierarchy_highlight_item');
                if(highlighted.length) {
                    highlighted.removeClass('hierarchy_highlight_item');
                    highlighted.parent().hide();
                }

            }
            else {
                if(el.next(".elm_content_step").length) {
                    el.addClass("mod--active");
                    el.nextAll(".elm_content_step").stop().slideDown();
                    setTimeout(function () {
                        el.nextAll(".elm_content_step").removeClass("state--loading");
                    },1000);
                }
            }

            if(!isActive) {
                $('[data-hierarchy-structure-item][data-parent-login="' + currentLogin + '"]').show();
            }

        });

        fn.on("click",'[data-click="expandFiltered"]',function (e) {
            e.preventDefault();

            let elmItem = $(this).closest('.data-hierarchy-item-filtered');

            let login = elmItem.attr('data-login');
            let parentLogin = elmItem.attr('data-parent-login');

            let isActive = elmItem.hasClass("mod--active");

            if(isActive) {
                elmItem.removeClass("mod--active");
            } else {
                elmItem.addClass("mod--active");
            }

            if(elmItem.is('[data-filtered-item]')) {
                if(parseInt(parentLogin) > 0) {

                    let parent = $('[data-login="' + parentLogin + '"]:not([data-filtered-item])');

                    if(isActive) {
                        parent.stop().slideUp();
                    } else {
                        parent.stop().slideDown();
                    }
                }
            }

            if(parseInt(login) > 0) {

                let children = $('[data-parent-login="' + login + '"]:not([data-filtered-item])');

                if(isActive) {
                    children.stop().slideUp();
                } else {
                    children.stop().slideDown();
                }

            }

        });

        function doFilterHierarchy(sortType)
        {
            let href = fn.find('.wrp_comp_filter').attr('data-ajax-link');

            if(!sortType) {
                sortType = '';
            }

            let name = $('.wrp_comp_filter').find('[data-filter-name]').val();
            let position = $('.wrp_comp_filter').find('[data-filter-position]').val();
            let toNextLevelRemaining = $('.wrp_comp_filter').find('[data-filter-remaining]').val();
            let onlyAs = $('.wrp_comp_filter').find('[data-filter-autoship]').prop('checked') ? 1 : 0;
            let onlyEnding = $('.wrp_comp_filter').find('[data-filter-ending-registration]').prop('checked') ? 1 : 0;
            let asNotInvoiced = $('.wrp_comp_filter').find('[data-filter-autoship-not-invoiced]').prop('checked') ? 1 : 0;
            let noOrderDayFrom = $('.wrp_comp_filter').find('[data-filter-no-order-day-from]').val();
            let noOrderDayTo = $('.wrp_comp_filter').find('[data-filter-no-order-day-to]').val();
            let filteredDate = $('.elm_head_datepicker').find('input[name="hierarchy_date"]').val();
            let country = $('.wrp_comp_filter').find('[data-filter-country]').val();

            let data = {
                name: name,
                position: position,
                toNextLevelRemaining: toNextLevelRemaining,
                onlyAs: onlyAs,
                onlyEnding: onlyEnding,
                asNotInvoiced: asNotInvoiced,
                sortType: sortType,
                noOrderDayFrom: noOrderDayFrom,
                noOrderDayTo: noOrderDayTo,
                filteredDate: filteredDate,
                country: country,
            };

            //loading
            body.addClass("is--loading");
            if ($("#layout_nav").hasClass("ssm-nav-visible")) {
                $(".ssm-nav-visible").removeClass("ssm-nav-visible");
                $(".ssm-overlay").fadeOut(300);
                $("htm").css("overflow","visible");
            }

            naja.makeRequest('POST', href, data,{history: false, timeout: 60000}).then(function(data) {
                if(typeof reinitHierarchyStructureDate !== 'undefined' && reinitHierarchyStructureDate) {
                    reinitHierarchyStructureDate();
                }
            });
        }

        fn.on('click','[data-hierarchy-sort]',function(e) {
            e.preventDefault();
            let sortType = $(this).attr('data-hierarchy-sort');
            doFilterHierarchy(sortType);
        });

        fn.on('click','[data-submit-hierarchy-filter]',function(e) {
            e.preventDefault();
            doFilterHierarchy();
        });

        fn.on('change','[data-filer-change]',function(e) {
            doFilterHierarchy();
        });

        /*fn.on('change','#hierarchy_date_alt',function(e) {
            doFilterHierarchy();
        });*/

        $(document).on('click','[data-toggle-all-hierarchy]',function(e) {
            e.preventDefault();
            if(!$(this).hasClass('active')) {
                $(this).find('i').text($(this).attr('data-hide-label'));
                $(this).addClass('active');
                $('.comp_hierarchy_structure .elm_content_step').show();
                $('.comp_hierarchy_structure .data-hierarchy-item-filtered').show();
            } else {
                $(this).find('i').text($(this).attr('data-show-label'));
                $(this).removeClass('active');
                $('.comp_hierarchy_structure .elm_content_step:not(.offset--1)').hide();
                $('.comp_hierarchy_structure .data-hierarchy-item-filtered:not([data-filtered-item])').hide();
            }
        });

        //sync scroll
        var scrollDivs = $('[data-scroll-sync-x="1"], [data-scroll-sync-x="2"]');
        var sync = function(e) {
            var $other = scrollDivs.not(this).off('scroll'), other = $other.get(0);
            var percentage = this.scrollLeft / (this.scrollWidth - this.offsetWidth);
            other.scrollLeft = percentage * (other.scrollWidth - other.offsetWidth);
            setTimeout( function(){ $other.on('scroll', sync ); },10);
        };
        scrollDivs.on( 'scroll', sync);
    }
})(jQuery);